import React from "react"

import Container from 'react-bootstrap/Container'

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"

import "./index.scss"

const NotFoundPage = () => (

	<Layout>
		<SEO title="404: Page not found" />
			<Container className="ftrs-content">

				<h1 className="ftrs-h1">404: Page not found</h1>
				<p>This content is not available on our website.</p>

				<Link className="ftrs-button ftrs-button--big mt-2 mb-5" to="/">
					Go back home
				</Link>
			</Container>
	</Layout>

)

export default NotFoundPage
